// TODO REMOVE
// USE CHECKADDRESSNEW !!!

import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'

import axios from 'axios'

export interface Address {
  city: string
  idLocalite: string
  idRadLocalite: string
  idRadRue: string
  localite: string
  rue: string
}

export const getCodeCountries = () => {
  const countries: any[] = []

  const params = {
    Langue: 'FR',
  }

  axios.get(`${getApiUrl()}/address/country`, { params }).then(({ data }) => {
    data.liste.forEach(element => {
      countries.push({ label: element.nomPays, value: element.codePays })
    })
    countries.sort((a, b) => a.label.localeCompare(b.label, 'fr'))
  })

  return countries
}

export const fetchArea = async (zip: string) => {
  let address: Address[] = []
  let localite = ''
  // let streets: string[] = []

  await axios
    .get(`${getApiUrl()}/address?Cdpostal=${zip}`)
    .then(data => {
      address = data.data.liste
      // eslint-disable-next-line
      localite = address[0].localite
      // streets = [
      //   ...new Set(
      //     address.map(addr => addr.rue).sort((a, b) => a.localeCompare(b, 'fr'))
      //   ),
      // ]
    })
    .catch(error => {
      logger.error('[FETCH CITIES] CP not in range')
    })

  return { localite, address }
}

export const getStreets = (address: Address[], area: string) => {
  const streets: string[] = [
    ...new Set(
      address
        .filter(addr => addr.localite === area)
        .map(addr => addr.rue)
        .sort((a, b) => a.localeCompare(b, 'fr'))
    ),
  ]

  return streets
}
