

















































































































































































































































































































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

import VSelect from '@/components/form/VSelectGood.vue'
import VInput from '@/components/form/VInput.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import GModal from '@/components/g/Modal.vue'
import PhoneValidForm from '@/components/my/PhoneValidForm.vue'
import ValidContactEmail from '@/components/my/modal/ValidContactEmail.vue'
import userAccountRequest from '@/composables/userAccountRequest'
import SelectPhoneFlag from '@/components/form/selectPhoneFlag.vue'
import { extend } from 'vee-validate'
import { email, regex, required } from 'vee-validate/dist/rules.umd.js'
import { Address, fetchArea, getStreets } from '@/composables/address'

extend('email', email)
extend('regex', regex)
extend('required', required)

export default defineComponent({
  name: 'my-user-form',
  components: {
    FeedbackMessage,
    VInput,
    VSelect,
    GModal,
    PhoneValidForm,
    SelectPhoneFlag,
    ValidContactEmail,
  },
  props: {
    // eslint-disable-next-line id-length
    showModalValidContactEmail: {
      type: Boolean,
      default: false,
      required: false,
    },
    model: {
      type: Object as () => Record<string, any>,
      required: true,
    },
    role: {
      type: String as () => 'view' | 'signup',
      required: true,
    },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const { user } = useState(['user'])

    const formName = 'myUserForm'
    const state = useState(['connection'])
    const phoneCode = ref()
    const phoneCountryRegex = ref('|(4)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')

    const refresher = ref(1)

    const phoneErrorMsg = ref()
    let validContactError = ref(false)

    const address = ref<Address[]>([])
    const streets = ref<string[]>([])

    if (props.role === 'view') {
      fetchArea(props.model.Adresse.Cdpostal).then(res => {
        streets.value = getStreets(res.address, res.localite)
      })
    }

    const firstSendSms = ref(true)

    const defaultPhoneNumber = props.model.Phone
      ? ref(props.model.Phone)
      : ref('')

    const phoneNumber = computed({
      get() {
        return defaultPhoneNumber.value.replace(
          // eslint-disable-next-line max-len
          /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/,
          ''
        )
      },
      set(val: string) {
        defaultPhoneNumber.value = val.replace(/^0|\//, '')
      },
    })

    if (
      defaultPhoneNumber.value.match(
        // eslint-disable-next-line max-len
        /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/,
        ''
      )
    ) {
      ;[phoneCode.value] = defaultPhoneNumber.value.match(
        // eslint-disable-next-line max-len
        /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/,
        ''
      )
    }

    ctx.root.$on(
      `phone:flag:updated:${formName}`,
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        state.connection.value.personalData.phoneCode = emitted.phoneCode
        phoneCode.value = emitted.phoneCode
        phonePlaceHolder.value = emitted.placeHolder
        phoneCountryRegex.value = emitted.regex
      }
    )

    watch(phoneCode, phoneCodeChange => {
      props.model.Phone = phoneCodeChange + phoneNumber.value
      refresher.value += 1
    })

    watch(defaultPhoneNumber, newNumber => {
      if (newNumber) {
        props.model.Phone = phoneCode.value + newNumber
        refresher.value += 1
      } else {
        props.model.Phone = ''
      }
    })

    const phoneError = computed(() => {
      const regex = new RegExp(`^(${phoneCountryRegex.value})$`)
      const isValid = regex.test(phoneNumber.value)

      phoneErrorMsg.value = []

      if (!isValid) {
        phoneErrorMsg.value.push(`${i18n.value.personnal.phoneError}`)
      }

      ctx.root.$emit('error:phone', !isValid)

      return isValid
    })

    watch(phoneError, newValue => {
      const bool = !newValue
      ctx.root.$emit('error:phone', Boolean(bool))
    })

    // LandlinePhone;;
    const defaultLandline = props.model.PhoneFixe
      ? ref(props.model.PhoneFixe)
      : ref('')

    const landlineNumber = computed({
      get() {
        return defaultLandline.value.replace('+32', '')
      },
      set(val: string) {
        defaultLandline.value = val.replace(/^0|\//, '')
      },
    })

    const landlineError = computed(() => {
      const isValid = /^(1[0-69]|[23][2-8]|4[23]|4[0-9]\d|5\d|6[01345789]|7[01689]|8[0-79]|9[012])\d{6}$/.test(
        landlineNumber.value
      )

      ctx.root.$emit('error:landline', !isValid)

      return isValid
    })

    const showModalcontactMail = ref(props.showModalValidContactEmail)

    // eslint-disable-next-line id-length
    const showModalValidContactEmailComputed = computed({
      get() {
        return showModalcontactMail.value
      },
      set(val: boolean) {
        ctx.root.$emit('updateContactMail', val)
      },
    })
    watch(landlineError, newValue => {
      const bool = !newValue
      ctx.root.$emit('error:landline', Boolean(bool))
    })

    watch(defaultLandline, newNumber => {
      if (newNumber) {
        props.model.PhoneFixe = `+32${newNumber}`
      } else {
        props.model.PhoneFixe = ''
      }
    })

    watch(
      () => props.model.Adresse.Cdpostal,
      cdpostal => {
        streets.value = []
        props.model.Adresse.Localite = null
        props.model.Adresse.Rue = null
        props.model.Adresse.NumRue = null

        if (cdpostal.length === 4) {
          fetchArea(cdpostal).then(res => {
            props.model.Adresse.Localite = res.localite
            address.value = res.address
            streets.value = getStreets(
              address.value,
              props.model.Adresse.Localite
            )
          })
        }
      }
    )

    watch(
      () => props.model.Adresse.Localite,
      _ => {
        streets.value = getStreets(address.value, props.model.Adresse.Localite)
        props.model.Adresse.Rue = null
        props.model.Adresse.NumRue = null
      }
    )

    watch(
      () => props.model.Adresse.Rue,
      _ => {
        props.model.Adresse.NumRue = null
      }
    )

    const sendSms = async () => {
      firstSendSms.value = true
      const { error } = await userAccountRequest(
        'users/activate/sendSms',
        'post',
        {
          Type: 'sendSms',
          Phone: props.model.Phone,
        }
      )
      setTimeout(() => {
        firstSendSms.value = false
      }, 60000)
    }
    const { currentLang } = useGetters(['currentLang'])
    const host = process.env.VUE_APP_MYRESA_DOMAIN
    const path = `/${currentLang.value}/profile/view/`

    const sendMail = async () => {
      const { error } = await userAccountRequest(
        'users/activate/sendMail/contact',
        'post',
        {
          Email: props.model.ContactEmail,
        },
        {
          params: {
            ContactEmail: true,
            ErrorCallback: host + path,
            Callback: host + path,
          },
        }
      )

      if (error) {
        validContactError = error
      }
    }

    watch(props, old => {
      if (props.showModalValidContactEmail) {
        sendMail()
      }
    })

    return {
      streets,
      i18n,
      user,
      defaultLandline,
      landlineError,
      landlineNumber,
      phoneNumber,
      defaultPhoneNumber,
      phoneError,
      phoneErrorMsg,
      refresher,
      showModal: false,
      PhoneValidForm,
      ValidContactEmail,
      firstSendSms,
      sendSms,
      formName,
      phoneCode,
      phonePlaceHolder,
      phoneCountryRegex,
      // eslint-disable-next-line id-length
      showModalValidContactEmailComputed,
      validContactError,
    }
  },
})
