var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.i18n.personnal)?_c('div',[_c('v-input',{staticClass:"col-xxxs-20 col-s-10 mb-s",attrs:{"id":_vm.i18n.personnal.fields.lastname,"disabled":!_vm.user.validContactEmail && _vm.role !== 'signup',"label":_vm.i18n.personnal.fields.lastname,"required":true,"pattern":"^[A-Za-zÀ-ÖØ-öø-ƿ\\-]+[\\sA-Za-zÀ-ÖØ-öø-ƿ\\-.,']*$"},model:{value:(_vm.model.Lastname),callback:function ($$v) {_vm.$set(_vm.model, "Lastname", $$v)},expression:"model.Lastname"}}),_c('v-input',{staticClass:"col-xxxs-20 col-s col-s-offset-1 mb-s",attrs:{"id":_vm.i18n.personnal.fields.firstname,"disabled":!_vm.user.validContactEmail && _vm.role !== 'signup',"label":_vm.i18n.personnal.fields.firstname,"required":true,"pattern":"^[A-Za-zÀ-ÖØ-öø-ƿ\\-]+[\\sA-Za-zÀ-ÖØ-öø-ƿ\\-.,']*$"},model:{value:(_vm.model.Firstname),callback:function ($$v) {_vm.$set(_vm.model, "Firstname", $$v)},expression:"model.Firstname"}}),(_vm.role !== 'view')?_c('v-input',{staticClass:"col-xxxs-20 mb-s",attrs:{"id":_vm.i18n.personnal.fields.email,"label":_vm.i18n.personnal.fields.email,"required":true,"tip":_vm.i18n.personnal.fields.emailCaution,"tipIconStyle":{
      fill: '#E84E0F',
      cursor: 'pointer',
      transform: 'translate(3px,3px)',
    },"tipPanelValue":_vm.i18n.validationPhone.emailWarnings.tip.postName,"tipPostType":_vm.i18n.validationPhone.emailWarnings.tip.postType,"tipStyle":{ color: 'rgb(87, 87, 86)', cursor: 'pointer' },"type":"email"},model:{value:(_vm.model.Email),callback:function ($$v) {_vm.$set(_vm.model, "Email", $$v)},expression:"model.Email"}}):_vm._e(),(_vm.role === 'view')?_c('v-input',{staticClass:"col-xxxs-20 mb-s",attrs:{"id":_vm.i18n.personnal.fields.email,"disabled":_vm.role === 'view',"label":_vm.i18n.personnal.fields.email,"required":true,"type":"email"},model:{value:(_vm.model.Email),callback:function ($$v) {_vm.$set(_vm.model, "Email", $$v)},expression:"model.Email"}}):_vm._e(),(_vm.role === 'view')?_c('v-input',{class:{
      'phone-warning':
        (_vm.role !== 'signup' &&
          !_vm.user.validContactEmail &&
          _vm.model.ContactEmail === _vm.user.contact.contactEmail) ||
        (_vm.validContactError && _vm.role !== 'signup'),
      'contact-email col-xxxs-20':
        _vm.role !== 'signup' &&
        (!_vm.user.validContactEmail ||
          _vm.validContactError ||
          _vm.model.ContactEmail !== _vm.user.contact.contactEmail),
      'col-xxxs-20 mb-s':
        _vm.role !== 'signup' &&
        _vm.user.validContactEmail &&
        _vm.model.ContactEmail === _vm.user.contact.contactEmail,
    },attrs:{"id":("" + (_vm.role === 'view' ? 'Contact ' : '') + (_vm.i18n.personnal.fields.email)),"label":("" + (_vm.role === 'view' ? _vm.i18n.personnal.fields.contact + ' ' : '') + (_vm.i18n.personnal.fields.email)),"required":true,"type":"email"},scopedSlots:_vm._u([(_vm.role !== 'signup')?{key:"right",fn:function(){return [(
          _vm.role !== 'signup' &&
          _vm.user.validContactEmail &&
          _vm.model.ContactEmail === _vm.user.contact.contactEmail
        )?_c('feedback-message',{staticClass:"right",attrs:{"content":{
          type: 'success',
          htmltext: _vm.i18n.validationContactEmail.valid,
        }}}):_vm._e(),(
          (_vm.role !== 'signup' &&
            !_vm.user.validContactEmail &&
            _vm.model.ContactEmail === _vm.user.contact.contactEmail) ||
          _vm.validContactError
        )?_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":'ui-error-filled',"size":"0 0 24 24"}}):_vm._e(),(
          _vm.role !== 'signup' &&
          _vm.model.ContactEmail !== _vm.user.contact.contactEmail &&
          !_vm.validContactError
        )?_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon info",attrs:{"symbol":'icons-faq-attention',"size":"0 0 24 24"}}):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.model.ContactEmail),callback:function ($$v) {_vm.$set(_vm.model, "ContactEmail", $$v)},expression:"model.ContactEmail"}}):_vm._e(),(
      _vm.role !== 'signup' &&
      _vm.model.ContactEmail !== _vm.user.contact.contactEmail &&
      !_vm.validContactError
    )?_c('feedback-message',{staticClass:"col-xxxs-20 mb-s",attrs:{"addStyles":{ color: '#E84E0F' },"content":{
      type: '',
      htmltext: _vm.i18n.validationContactEmail.label,
    },"isSvg":false}}):_vm._e(),(_vm.validContactError)?_c('feedback-message',{staticClass:"col-xxxs-20 mb-s",attrs:{"addStyles":{ color: '#E84E0F' },"content":{
      type: '',
      htmltext: _vm.i18n.validationContactEmail.error,
    },"isSvg":false}}):_vm._e(),_c('div',{on:{"click":function($event){_vm.showModalValidContactEmailComputed = true}}},[(
        _vm.role !== 'signup' &&
        !_vm.user.validContactEmail &&
        _vm.model.ContactEmail === _vm.user.contact.contactEmail
      )?_c('feedback-message',{staticClass:"col-xxxs-20 mb-s",attrs:{"addStyles":{ color: '#E84E0F' },"content":{
        type: '',
        htmltext: _vm.i18n.validationContactEmail.labelRetry,
      },"isSvg":false}}):_vm._e()],1),_c('g-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModalValidContactEmail),expression:"showModalValidContactEmail"}],attrs:{"addStyles":{ 'border-radius': '20px' },"component":_vm.ValidContactEmail,"propsComponent":{
      contactEmail: _vm.model.ContactEmail,
    },"size":"small"},on:{"close-modal":function($event){_vm.showModalValidContactEmailComputed = false}}}),_c('div',{staticClass:"col-xxxs-20 col-s-10 mb-s"},[_c('div',{staticClass:"row bottom-xxxs"},[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.personnal.fields.landline)+" ")]),_c('div',{staticClass:"phone"},[_vm._m(0),_c('v-input',{attrs:{"id":"fixe","maxlength":8,"minlength":8,"placeholder":"42201211","type":"tel"},model:{value:(_vm.landlineNumber),callback:function ($$v) {_vm.landlineNumber=$$v},expression:"landlineNumber"}})],1)]),(!_vm.landlineError && _vm.defaultLandline.length > 0)?_c('feedback-message',{staticClass:"col-xxxs-20 mt-xxs",attrs:{"content":{ type: 'error', htmltext: _vm.i18n.personnal.phoneError }}}):_vm._e()],1),(_vm.role !== 'view')?_c('span',{staticClass:"col-xxxs-20 col-s-9 col-s-offset-1 mb-s"},[_c('div',{staticClass:"row bottom-xxxs"},[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.personnal.fields.phone)+" * ")]),_c('div',{staticClass:"phone"},[_c('SelectPhoneFlag',{attrs:{"formName":_vm.formName,"selectedPhoneCode":_vm.phoneCode}}),_c('ValidationProvider',{attrs:{"custom-messages":{
            regex: _vm.i18n.personnal.phoneError,
            required: _vm.i18n.personnal.phoneError,
          },"rules":{
            regex: ("^(" + _vm.phoneCountryRegex + ")$"),
            required: false,
          },"name":"phone","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{attrs:{"id":"gsm-personal-phone","errors":errors,"pattern":("^(" + _vm.phoneCountryRegex + ")$"),"placeholder":("" + _vm.phonePlaceHolder),"required":true},on:{"input":validate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,false,632044536)})],1)])]):_vm._e(),(_vm.role === 'view')?_c('span',{staticClass:"col-xxxs-20 col-s-9 col-s-offset-1 mb-s"},[_c('div',{staticClass:"row bottom-xxxs"},[_c('div',{class:{ 'phone-warning': !_vm.user.validPhone }},[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.personnal.fields.phone)+" * ")]),_c('div',{staticClass:"phone"},[_c('SelectPhoneFlag',{attrs:{"formName":_vm.formName,"selectedPhoneCode":_vm.phoneCode}}),_c('ValidationProvider',{attrs:{"custom-messages":{
              regex: _vm.i18n.personnal.phoneError,
              required: _vm.i18n.personnal.phoneError,
            },"rules":{
              regex: ("^(" + _vm.phoneCountryRegex + ")$"),
              required: true,
            },"name":"phone","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var validate = ref.validate;
return [_c('v-input',{attrs:{"id":"gsm-personal-phone","errors":_vm.phoneErrorMsg,"pattern":("^(" + _vm.phoneCountryRegex + ")$"),"placeholder":("" + _vm.phonePlaceHolder),"required":true},on:{"input":validate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,false,2246843305)})],1)])]),_c('div',{on:{"click":function($event){_vm.sendSms()
        _vm.showModal = true}}},[(!_vm.user.validPhone)?_c('feedback-message',{staticClass:"col-xxxs-20 mt-xxs",staticStyle:{"padding-left":"10px","margin-top":"4px"},style:(_vm.phoneError ? 'margin-top:0.5rem' : 'margin-top:2rem'),attrs:{"content":{
          htmltext: _vm.i18n.personnal.fields.phoneCaution,
        },"isSvg":false}}):_vm._e()],1)]):_vm._e(),(_vm.role === 'view')?_c('g-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],key:_vm.refresher,attrs:{"component":_vm.PhoneValidForm,"propsComponent":{
      phoneNumber: _vm.phoneCode + _vm.phoneNumber,
      isFirstSend: _vm.firstSendSms,
      parentInputStyle: { border: 'none' },
    }},on:{"close-modal":function($event){_vm.showModal = false}}}):_vm._e(),_c('v-input',{staticClass:"col-xxxs-20 col-xs-5 mb-s",attrs:{"id":_vm.i18n.personnal.fields.zipcode,"label":_vm.i18n.personnal.fields.zipcode,"maxlength":4,"required":true,"max":"9999","min":"1000"},model:{value:(_vm.model.Adresse.Cdpostal),callback:function ($$v) {_vm.$set(_vm.model.Adresse, "Cdpostal", $$v)},expression:"model.Adresse.Cdpostal"}}),_c('v-input',{staticClass:"col-xxxs-20 col-xs-14 col-xs-offset-1 mb-s",attrs:{"id":_vm.i18n.personnal.fields.city,"disabled":true,"label":_vm.i18n.personnal.fields.city,"required":true},model:{value:(_vm.model.Adresse.Localite),callback:function ($$v) {_vm.$set(_vm.model.Adresse, "Localite", $$v)},expression:"model.Adresse.Localite"}}),_c('div',{staticClass:"col-xxs-20 col-xs-16"},[_c('label',{staticClass:"label headline",attrs:{"for":"Rue"}},[_vm._v(" "+_vm._s(_vm.i18n.personnal.fields.street)+" "),_c('sup',[_vm._v("*")])]),_c('v-select',{attrs:{"id":_vm.i18n.personnal.fields.street,"disable":!_vm.model.Adresse.Localite,"dynamicOrientation":true,"filterable":true,"options":_vm.streets,"placeholder":_vm.i18n.select,"required":true},model:{value:(_vm.model.Adresse.Rue),callback:function ($$v) {_vm.$set(_vm.model.Adresse, "Rue", $$v)},expression:"model.Adresse.Rue"}})],1),_c('v-input',{staticClass:"col-xxxs-20 col-xs col-xs-offset-1 mb-s",attrs:{"id":_vm.i18n.personnal.fields.number,"disabled":!_vm.model.Adresse.Rue,"label":_vm.i18n.personnal.fields.number,"required":true},model:{value:(_vm.model.Adresse.NumRue),callback:function ($$v) {_vm.$set(_vm.model.Adresse, "NumRue", $$v)},expression:"model.Adresse.NumRue"}})],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"country-codes"},[_c('img',{staticClass:"phone__flag",attrs:{"alt":"Belgium","src":require("../../assets/images/flag-be.svg")}}),_c('span',{staticClass:"phone__code"},[_vm._v("+32")])])}]

export { render, staticRenderFns }