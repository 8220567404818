

















import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'valid-contact-email',
  props: {
    contactEmail: { type: String, required: false },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const confirm = () => {
      ctx.root.$emit('modal:confirm:yes')
    }

    return {
      confirm,
      i18n,
    }
  },
})
