

















































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { useState, useMutations } from '@u3u/vue-hooks'
import auth from '@/composables/auth'
import userAccountRequest from '@/composables/userAccountRequest'
import VInput from '@/components/form/VInput.vue'
import { codeMask } from '@/components/form/selectPhoneFlag.vue'

export default defineComponent({
  name: 'phone-valid-form',
  components: { VInput },
  props: {
    phoneNumber: { type: String, default: '47X XX XX 75' },
    parentInputStyle: {
      type: Object,
      default: () => ({}),
    },
    isFirstSend: { type: Boolean, required: false, default: false },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const { UPDATE_USER } = useMutations('user', ['UPDATE_USER'])
    const { $route, $router } = ctx.root
    const params = {
      Token: $route.query.Token,
    }
    const smsCode = ref('')
    const errorMessage = ref(false)
    const isSending = ref(false)
    const isReSending = ref(false)
    const retryMessage = ref(false)
    const succesMessage = ref(false)

    const countryDigits = ref(
      props.phoneNumber.match(
        // eslint-disable-next-line max-len
        /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/
      )
    )

    const lastDigits = computed(() => {
      let digits = (props.phoneNumber as string).substring(
        countryDigits!.value![0].length
      )

      digits = ctx.root.$options?.filters?.VMask(
        digits,
        codeMask[countryDigits!.value![0]]
      )

      return digits
    })

    const formatedNumber = ref(
      `${countryDigits!.value![0]} ${lastDigits.value}`
    )

    const resendSms = async () => {
      isReSending.value = true
      const { error } = await userAccountRequest(
        'users/activate/sendSms',
        'post',
        { Type: 'sendSms', Phone: props.phoneNumber.replace(/\s/g, '') },
        { params }
      )
      setTimeout(() => {
        isReSending.value = false
      }, 60000)
    }

    const onSubmit = async () => {
      isSending.value = true
      errorMessage.value = false
      retryMessage.value = false

      const { error } = await userAccountRequest(
        'users/activate/checkSms',
        'post',
        { Code: smsCode.value },
        { params }
      )

      if (error) {
        errorMessage.value = true
        setTimeout(() => {
          isSending.value = false
          retryMessage.value = true
        }, 10000)
        smsCode.value = ''
      } else if ($route.query.Token) {
        $router.push({
          name: 'MyPassword',
          query: { Token: `${$route.query.Token}`, SmsValidate: 'true' },
        })
      } else {
        // await auth.refresh()
        UPDATE_USER({ validPhone: true })
        succesMessage.value = true
      }
    }

    return {
      i18n,
      smsCode,
      errorMessage,
      isSending,
      isReSending,
      retryMessage,
      succesMessage,
      formatedNumber,
      resendSms,
      onSubmit,
    }
  },
})
