
















































































import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'v-input',
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    label: String,
    id: String,
    checked: Boolean,
    html: String,
    model: [Array, String, Number, Boolean],
    required: {
      type: Boolean,
      default: false,
    },
    labelStyle: {
      type: Object,
      default: () => ({}),
    },
    value: String,
    name: String,
  },

  setup(props, ctx) {
    const isChecked = computed(() => {
      if (props.checked) {
        return props.checked
      }

      if (Array.isArray(props.model)) {
        return props.model.some(item => item === props.value)
      }

      return props.model
    })

    const handleChange = e => {
      let value = e.target.checked

      if (Array.isArray(props.model)) {
        ;({ value } = e.target)
        let values

        if (props.model.some(item => item === value)) {
          values = props.model.filter(item => item !== value)
        } else {
          values = props.model
          values.push(value)
        }

        ctx.emit('change', values)
      } else {
        ctx.emit('change', value)
      }
    }

    return {
      isChecked,
      handleChange,
    }
  },
})
