var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.$route.query.Token && !_vm.succesMessage)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.i18n.validationPhone.form.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.i18n.validationPhone.form.subtitle))])]):_vm._e(),(!_vm.succesMessage)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('p',{staticClass:"validation-label",domProps:{"innerHTML":_vm._s(
        ((_vm.i18n.validationPhone.form.label) + "<strong style='color:#e84e0f'> " + _vm.formatedNumber + "</strong>")
      )}}),_c('div',{staticClass:"row form-validation"},[_c('div',{class:_vm.$route.query.Token
            ? 'col-xxxs-20 col-s-16 mb-s mt-xs'
            : 'col-xxxs-20 col-s-6 mb-s mt-xs'},[_c('v-input',{attrs:{"type":"tel","id":"validation","disabled":_vm.isSending,"parentDivStyle":_vm.parentInputStyle,"inputStyle":{
            border: 'solid black 1px',
            'border-radius': '99em',
            'font-weight': 'bold',
            'font-size': '2.5rem',
            'letter-spacing': '1.2rem',
            padding: '10px',
          },"minlength":6,"maxlength":6},model:{value:(_vm.smsCode),callback:function ($$v) {_vm.smsCode=$$v},expression:"smsCode"}})],1),_c('div',{staticClass:"col-xxxs-20 col-s-4 mb-s mt-xs submit-btn-parent"},[_c('g-action',{staticClass:"submit-number content__btn action--btn--full--link--icon--arrows-large-right",attrs:{"content":{
            tag: 'button',
            label: _vm.i18n.validationPhone.form.button.label,
            icon: 'arrow',
          },"type":"submit","disabled":_vm.isSending || _vm.smsCode.length < 6}})],1)]),(_vm.errorMessage)?_c('p',{domProps:{"innerHTML":_vm._s(
        ((_vm.i18n.validationPhone.form.error) + " " + (_vm.retryMessage
            ? _vm.i18n.validationPhone.form.retry
            : _vm.i18n.validationPhone.form.timeoutWarning))
      )}}):_vm._e(),(_vm.$route.query.Token)?_c('p',{staticClass:"sms-resend",domProps:{"innerHTML":_vm._s(_vm.i18n.validationPhone.form.complement)}}):_vm._e(),(!_vm.isReSending && !_vm.isFirstSend)?_c('p',{staticClass:"sms-resend",domProps:{"innerHTML":_vm._s(("" + (_vm.i18n.validationPhone.form.troubleshoot)))},on:{"click":_vm.resendSms}}):_vm._e(),(_vm.isReSending)?_c('p',{staticClass:"sms-resend",staticStyle:{"color":"#e84e0f"}},[_vm._v(" "+_vm._s(_vm.i18n.validationPhone.form.isSend)+" ")]):_vm._e()]):_vm._e(),(_vm.succesMessage)?_c('div',[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.i18n.validationPhone.form.succesMessage)}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }